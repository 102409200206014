


























import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({name: 'welcome-to-pigeon'})
export default class WelcomeToPigeon extends Vue {
  @Prop({required: true}) subtitle?: string
  @Prop({required: true}) buttonText?: string
  @Prop({required: true}) showLink?: boolean

}
